import type {ReactNode} from 'react';

import type {DeserializedData} from '~/features/serialization/types';

interface BreadcrumbParams<T> {
  id: string;
  pathname: string;
  // undefined if the loader errored or thrown response
  data: DeserializedData<T> | undefined;
}

export function createRouteHandleWithBreadcrumb<LoaderFn>(renderer: (params: BreadcrumbParams<LoaderFn>) => ReactNode) {
  return {
    // This seems dangerous, but there's no elegant way to type this with remix+typescript
    // As long as we only create and render breadcrumbs from these functions, we should be fine
    // Any mistakes in this should be visible during build time
    breadcrumb: (match: any) => renderer(match),
  };
}

export function extractBreadcrumbsFromRouteMatches<RouteMatch extends {id: string; handle: unknown}>(
  matches: RouteMatch[]
) {
  return matches.filter(isRouteWithBreadcrumb).map(match => ({
    id: match.id,
    breadcrumb: match.handle.breadcrumb(match),
  }));
}

function isRouteWithBreadcrumb<T extends {handle: unknown}>(
  route: T
): route is T & {handle: T['handle'] & {breadcrumb: (match: any) => ReactNode}} {
  return typeof route.handle === 'object' && route.handle !== null && 'breadcrumb' in route.handle;
}
